import MessageModal from 'components/common/modal/MessageModal';
import React from 'react'
import { useNavigate } from 'react-router-dom'
import useFormDataStore from 'store/formState';
import useModalStore from 'store/modalState';

const GameForms = ({ step, setStep, data }) => {
	const navigate = useNavigate();
	const formResultData = useFormDataStore((state) => state.formResultData)
	const updateFormResultData = useFormDataStore((state) => state.updateFormResultData)
	const updateFormResultState = useFormDataStore((state) => state.updateFormResultState)
	const { openModal, closeModal } = useModalStore();

	const isListActive = (itemIdx) => {
		const activeList = formResultData.Q5?.aList.map((item) => { return item.num })
		return activeList?.includes(itemIdx) && activeList?.indexOf(itemIdx) + 1
	}

	return (
		<>
			<div className='q'>
				<span>Q5.</span>
				<h3>자주 즐기시는 게임이 있으신가요? (복수 선택 가능)</h3>
			</div>
			<ul className='a-img'>
				{data.map((item, i) => (
					<li key={`game-list-item-${i}`} className={isListActive(i + 1) ? 'active' : ''} onClick={() => {
						const isActiveIdx = isListActive(i + 1);
						if (isActiveIdx) {
							let aListCopy = [...formResultData.Q5?.aList];
							let dataCopy = [...formResultData.Q5?.data];
							aListCopy.splice(isActiveIdx - 1, 1)
							dataCopy.splice(isActiveIdx - 1, 1)
							updateFormResultData({
								...formResultData,
								Q5: {
									q: '자주 즐기시는 게임이 있으신가요?',
									aList: [
										...aListCopy
									],
									data: [
										...dataCopy
									]
								}
							})
						} else {
							updateFormResultData({
								...formResultData,
								Q5: {
									q: '자주 즐기시는 게임이 있으신가요?',
									aList: [
										...formResultData.Q5?.aList || [],
										{
											num: i + 1,
											text: item.nameKor
										},
									],
									data: [
										...formResultData.Q5?.data || [],
										item.id
									]
								}
							})
						}
						updateFormResultState('Q5')
					}}>
						<div className="img-box">
							<img src={item.image.url} alt="" />
							<div className="overlay"></div>
						</div>
						<div className='a-text'>
							<div className='num'>
								<span>{i + 1}</span>
							</div>
							<p>{item.nameKor}</p>
						</div>
					</li>
				))}
			</ul>
			<div className='btn-container'>
				{/* <button className='btn btn-force' onClick={() => {
					updateFormResultData({
						...formResultData,
						Q5: {
							q: '자주 즐기시는 게임이 있으신가요?',
							aList: [],
							aNone: '게임 안해요'
						}
					})
					updateFormResultState('Q5')
					setStep(step + 1)
				}}>
					게임 안해요
				</button> */}
				<button className='btn btn-prev' onClick={() => {
					navigate("/forms", {
						state: {
							step: 4
						}
					});
				}}>
					뒤로
				</button>
				<button
					className='btn btn-next'
					onClick={() => {
						if (formResultData.Q5 && formResultData.Q5?.aList.length) {
							setStep(step + 1)
						} else {
							openModal({
								title: '게임 선택 안내',
								message: '즐기시는 게임을 선택해 주세요',
								// subBtn: {
								// 	text: '게임을 안해요',
								// 	fnc: () => {
								// 		updateFormResultData({
								// 			...formResultData,
								// 			Q5: {
								// 				q: '자주 즐기시는 게임이 있으신가요?',
								// 				aList: [],
								// 				aNone: '게임을 안해요'
								// 			}
								// 		})
								// 		updateFormResultState('Q5')
								// 		setStep(step + 1)
								// 		closeModal();
								// 	},
								// },
								mainBtn: {
									text: '선택할게요',
									fnc: () => { closeModal() },
								}
							})
						}
					}}>다음</button>
			</div>
		</>
	)
}

export default GameForms