import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

const useFormDataStore = create()(
	devtools(
		// persist( @ 새로고침 후에도 양식이 저장되어야하면 모든 주석 풀기
		(set) => ({
			formResultData: {},
			formResultState: null,
			updateFormResultData: (formResultData) => set(() => ({ formResultData })),
			updateFormResultState: (formResultState) => set(() => ({ formResultState })),
		}),
		// {
		// name: 'formResultData',
		// storage: createJSONStorage(() => localStorage),
		// }
		// )
	),
)

export default useFormDataStore;