
import { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Layout from "components/ui/Layout";
import Main from "pages/main/Main";
import QnaFormsLayout from "pages/qnaForms/QnaFormsLayout";
import DetailedFormsLayout from "pages/detailedForms/DetailedFormsLayout";
import Recommend from "pages/recommend/Recommend";

function App() {

  const ScrollToTop = ({ children }) => {
    const { pathname } = useLocation();
    useLayoutEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return children;
  };

  return (
    <>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route element={<Layout />}>
            <Route path="/forms" element={<QnaFormsLayout />} />
            <Route path="/detailed" element={<DetailedFormsLayout />} />
            <Route path="/recommend" element={<Recommend />} />
          </Route>
        </Routes>
      </ScrollToTop>
    </>
  );
}

export default App;
