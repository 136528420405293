import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useFormDataStore from 'store/formState'
import useModalStore from 'store/modalState'

const QnaForms = ({ formData, step, setStep }) => {
	const formResultData = useFormDataStore((state) => state.formResultData)
	const updateFormResultData = useFormDataStore((state) => state.updateFormResultData)
	const updateFormResultState = useFormDataStore((state) => state.updateFormResultState)
	const navigate = useNavigate();
	const { openModal, closeModal } = useModalStore();

	const isListActive = (itemIdx,) => {
		const activeList = formResultData['Q' + (step + 1)]?.aList.map((item) => { return item.num })
		return activeList?.includes(itemIdx) ? 'active' : ''
	}


	return (
		<>
			{
				formData[step]?.type
					?
					<div>
						<div className='q'>
							<span>Q{step + 1}.</span>
							<h3>{formData[step].q} {formData[step].qs}</h3>
						</div>
						<ul className='a'>
							{formData[step].a.map((item, i) => (
								<li key={`qna-list-item-${i}`} className={isListActive(i + 1)} onClick={() => {
									updateFormResultData({
										...formResultData,
										['Q' + (step + 1)]: {
											q: formData[step].q,
											aList: [
												{
													num: i + 1,
													text: item.text
												},
											],
											data: item.data
										}
									})
									updateFormResultState('Q' + (step + 1))
								}}>
									<div>
										<span>
											{i + 1}
										</span>
									</div>
									<p>
										{item.text}
									</p>
								</li>))}
						</ul>
						<div className='btn-container'>
							{Boolean(step) &&
								<button className='btn btn-prev' onClick={() => {
									setStep(step - 1)
								}}>
									뒤로
								</button>
							}
							{step + 1 <= formData.length &&
								<button
									className='btn btn-next'
									onClick={() => {
										if (formResultData['Q' + (step + 1)]) {
											setStep(step + 1)
										} else {
											openModal({
												title: '답변 선택이 필요해요',
												message: `답변을 필수로 해주셔야 더욱 더 정확하게\n컴퓨터를 추천해드릴 수 있습니다.`,
												mainBtn: {
													text: '네 선택할게요',
													fnc: () => { closeModal() },
												}
											})
										}
									}}>다음</button>
							}
						</div>
					</div>
					:
					<div>
						<div className='q'>
							<h3>이제! 자주 사용하시는 프로그램을 선택해 보실께요!</h3>
						</div>
						<p className='info'>
							게임, OA, 디자인, 개발 관련 프로그램들을 안내 드릴께요.<br />
							자주 사용하시는 프로그램을 선택해 주시면 PC 추천이 진행 됩니다!
						</p>
						<div className='btn-container'>
							<button className='btn btn-prev' onClick={() => {
								setStep(step - 1)
							}}>
								뒤로
							</button>
							<button className='btn btn-next' onClick={() => {
								navigate('/detailed')
							}}>다음</button>
						</div>
					</div>
			}
		</>

	)
}

export default QnaForms