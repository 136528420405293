import React from 'react';
import header_logo from 'assets/logo/header_logo.png';
import { useNavigate } from 'react-router-dom'
import useFormDataStore from 'store/formState';
import useModalStore from 'store/modalState';

const Header = () => {
  const navigate = useNavigate();
  const updateFormResultData = useFormDataStore((state) => state.updateFormResultData)
  const updateFormResultState = useFormDataStore((state) => state.updateFormResultState)
  const { openModal, closeModal } = useModalStore();

  return (
    <div className='header'>
      <button onClick={() => {
        openModal({
          title: '메인페이지로 이동 하시겠어요?',
          message: '페이지 이동 시 진행하던 설문 데이터는 저장되지 않습니다.',
          subBtn: {
            text: '메인페이지 이동',
            fnc: () => {
              navigate('/');
              updateFormResultData({})
              updateFormResultState(null)
              closeModal();
            },
          },
          mainBtn: {
            text: '설문 계속하기',
            fnc: () => {
              closeModal();
            },
          }
        })


      }}>
        <img src={header_logo} alt="" />
      </button>
    </div>
  )
}

export default Header