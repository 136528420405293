import api from "api";


export const hardware = {
	email(body, callback) {
		api.post('/hardware/email',
			body
		).then((res) => {
			if (res.code !== 10000) return;
			callback(res.data);
		}).catch((res) => {
			callback(res);
		})
	},
};