import api from "api";


export const survey = {
	upload(body, callback) {
		api.post('/survey',
			body
		).then((res) => {
			if (res.code !== 10000) return;
			callback(res.data);
		}).catch((res) => {
			callback(res);
		})
	},
};