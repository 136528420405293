import React, { useEffect, useState } from 'react'
import useFormDataStore from 'store/formState';
import { ReactComponent as ArrowUp } from "assets/icons/arrow-up.svg";
import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg";
import { ReactComponent as LeftSpeechBubble } from "assets/icons/left-speech-bubble.svg";

const FormResult = () => {
	const formResultData = useFormDataStore((state) => state.formResultData)
	const formResultState = useFormDataStore((state) => state.formResultState)
	const [formResultActive, setFormResultActive] = useState(true);
	const [activeItem, setActiveItem] = useState(null);

	useEffect(() => {
		const formResultDataKey = Object.keys(formResultData)
		setActiveItem(formResultDataKey.indexOf(formResultState))
	}, [formResultState])

	return (
		<>
			{formResultActive ?
				<div className='form-result'>
					<div className='title' onClick={() => {
						setFormResultActive(false)
					}}>
						<p>
							피씨스터 PC 추천
						</p>
						<ArrowUp fill={'#fff'} />
					</div>
					<ul>
						{Object.keys(formResultData).map((item, i) => (
							<li key={`form-result-data-item-${i}`}>
								<div className='result-q' onClick={(e) => {
									if (activeItem === i) {
										setActiveItem(null)
									} else {
										setActiveItem(i)
									}
								}}>
									<p>{item}. {formResultData[item].q}</p>
									{activeItem === i ?
										<ArrowUp fill={'#4E3F96'} />
										:
										<ArrowDown fill={'#e0e0e0'} />
									}
								</div>
								{activeItem === i && <div className='result-a'>
									{
										formResultData[item].aList.length ?
											<>
												{formResultData[item].aList.map((aItem, j) => (
													<React.Fragment key={`a-list-item-${j}`}>
														{
															aItem.text ?
																<div className='text'>
																	<div className='num'>
																		<span>
																			{aItem.num}
																		</span>
																	</div>
																	<p>
																		{aItem.text}
																	</p>
																</div>
																:
																<>
																	<div className='img'>
																		<img src={aItem.img} alt='' />
																	</div>
																</>
														}
													</React.Fragment>
												))}
											</>
											:
											<>
												{formResultData[item].aNone &&
													<p>
														{formResultData[item].aNone}
													</p>
												}
											</>
									}
									{
										formResultData[item]?.a2 &&
										<div className='text a2'>
											<div className='num'>
												<span>
													{formResultData[item].a2.num}
												</span>
											</div>
											<p>
												{formResultData[item].a2.text}
											</p>
										</div>
									}
								</div>}
							</li>
						))}
					</ul>
				</div>
				:
				<div className='form-result-float-btn'>
					<button onClick={() => {
						setFormResultActive(true)
					}}>
						<LeftSpeechBubble />
					</button>
				</div>
			}
		</>
	)
}

export default FormResult