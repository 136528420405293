import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from './App';
import "styles/reset.scss";
import "styles/common.scss";
import "styles/sub.scss";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

if (rootElement.hasChildNodes()) {
  root.hydrate(
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
} else {
  root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}
