import api from "api";


export const banner = {
	get(params, callback) {
		api.get('/banner/act',
			{
				params
			}
		).then((res) => {
			if (res.code !== 10000) return;
			callback(res.data);
		})
	},
};