import React, { useEffect, useState } from 'react';
import { ReactComponent as LoadingIcon } from "assets/icons/loading.svg";
import { ReactComponent as ResetIcon } from "assets/icons/fluent_arrow-reset-24-filled.svg";
import { ReactComponent as SendIcon } from "assets/icons/mingcute_send-line.svg";
import { ReactComponent as LinkIcon } from "assets/icons/ri_link.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close-solid.svg";
import useFormDataStore from 'store/formState';
import { survey } from 'services/survey';
import { comma, emailRFC5322RegEx } from 'utils/regEx';
import { useNavigate } from 'react-router-dom';
import { hardware } from 'services/hardware';
import useModalStore from 'store/modalState';

const Recommend = () => {
	const navigate = useNavigate();
	const [result, setResult] = useState(null);
	const [isLoading, setLoading] = useState(true);
	const [mailList, setMailList] = useState([]);
	const [mailInput, setMailInput] = useState('');
	const [mailInputError, setMailInputError] = useState(false);
	const { openModal, closeModal } = useModalStore();

	const formResultData = useFormDataStore((state) => state.formResultData)
	const updateFormResultData = useFormDataStore((state) => state.updateFormResultData)
	const updateFormResultState = useFormDataStore((state) => state.updateFormResultState)

	useEffect(() => {
		if (!formResultData.Q1) {
			navigate('/');
			return;
		}
		const body = {
			useCase: formResultData.Q1?.data,
			age: formResultData.Q2?.data,
			gender: formResultData.Q3?.data,
			spec: formResultData.Q4?.data,
			browserTab: formResultData.Q7?.data,
			software: [
				...formResultData.Q5?.data,
				...formResultData.Q6?.data,
			],
			messengerType: [
				...formResultData.Q8?.data,
			],
			messengerCnt: formResultData.Q8?.a2.data,
			price: formResultData.Q9?.data
		}
		survey.upload(body, (res) => {
			if (res.code) {
				navigate('/');
			} else {
				for (let i = 0; i < res.software.length; i++) {
					if (res.software[i].hardware.length) {
						setResult(res.software[i].hardware[0])
						setTimeout(() => setLoading(false), 2000);
						break;
					}
				}
				// TODO 오류상황
				// navigate('/');
			}
		})
	}, [formResultData])

	const mailSend = () => {
		if (!mailList.length) {
			openModal({
				title: '발송할 메일 주소가 없습니다',
				message: `메일주소를 추가해주세요`,
				mainBtn: {
					text: '확인',
					fnc: () => {
						closeModal();
					},
				}
			})
			return;
		};
		const body = {
			email: mailList.join(','),
			hardwareId: result.id
		}
		hardware.email(body, () => {
			openModal({
				title: '메일 발송이 완료되었습니다',
				message: ``,
				mainBtn: {
					text: '확인',
					fnc: () => {
						setMailList([]);
						closeModal();
					},
				}
			})
		})
	}

	return (
		<>
			{
				isLoading ?
					<div id='analyze' className='contents'>
						<LoadingIcon className='loading-icon' />
						<h3>피씨스터가 지금 <strong>분석 중</strong>입니다</h3>
						<p>조금만 기다려주세요! 선택하신 정보들을 기준으로 분석 중에 있습니다!</p>
					</div>
					:
					<div id='recommend' className='contents'>
						<div className='tooltip'>
							<div></div>
							<p>
								피씨스터 분석이 완료 되었어요!
							</p>
							<div>
								<button onClick={() => {
									navigate('/forms');
									updateFormResultData({})
									updateFormResultState(null)
								}}>
									<p>처음부터 다시</p>
									<ResetIcon />
								</button>
							</div>
						</div>
						<div className='recommend-container'>
							{/* <img src={result.software[0].image.url} alt="" /> */}
							<div className='recommend-table-container'>
								<div className='recommend-table table'>
									<div className='thead'>
										<div>
											추천 컴퓨터
										</div>
									</div>
									{/* <tr>
											<th>모니터</th>
											<td>{result.memory}</td>
										</tr> */}

									<div className='th'>CPU</div>
									<div className='td'>{result.cpu}</div>

									<div className='th'>메모리</div>
									<div className='td'>{result.memory}</div>

									<div className='th'>그래픽</div>
									<div className='td'>{result.gpu}</div>

									<div className='th'>SSD</div>
									<div className='td'>{result.storage}</div>

									<div className='th'>윈도우</div>
									<div className='td'>{result.os}</div>

									<div className='tfoot'>
										<div>
											<p>판매 가격</p>
											<strong>{comma(result.price)}</strong>
											<p>원</p>
										</div>
									</div>
								</div>
							</div>
							<div>
								<h3>
									피씨스터가 드린 사양과 가격을 참고하여 컴퓨터를 구매하여 보세요!<br />
									너무 높은 가격대의 컴퓨터가 꼭 좋은 것은 아니에요
								</h3>
								<div className='recommend-input-box'>
									<p>사양정보 이메일 발송</p>
									<div>
										<input
											type="email"
											name="" id=""
											placeholder='이메일을 입력해주세요' className={mailInputError ? 'error' : ''} value={mailInput}
											onChange={(e) => { setMailInput(e.target.value); setMailInputError(false); }} disabled={mailList.length >= 10}
											onKeyDown={(e) => {
												if (e.key === 'Enter' || e.keyCode === 13) {
													if (!mailInput) return;
													if (emailRFC5322RegEx(mailInput)) {
														setMailList([
															...mailList,
															mailInput
														]);
														setMailInput('');
													} else {
														setMailInputError(true);
													}
												}
											}}
										/>
										<button type="button" className='add' onClick={() => {
											if (!mailInput) return;
											if (emailRFC5322RegEx(mailInput)) {
												setMailList([
													...mailList,
													mailInput
												]);
												setMailInput('');
											} else {
												setMailInputError(true);
											}

										}}>
											<PlusIcon />
											<p>
												메일 추가
											</p>
										</button>
										<button type="submit" className='submit' onClick={() => {
											mailSend();
										}}>
											<SendIcon />
											<p>
												메일 발송
											</p>
										</button>
									</div>
									<ul className='mail-list'>
										{
											Boolean(mailList.length) && mailList.map((item, i) => (
												<li>
													<p>{item}</p>
													<button className='del' onClick={() => {
														const tempMailList = [...mailList];
														tempMailList.splice(i, 1)
														setMailList(tempMailList)
													}}>
														<CloseIcon />
													</button>
												</li>
											))
										}
									</ul>
									<div className='mail-list-cnt'>
										{mailList.length} / 10
									</div>
								</div>
								<div className='recommend-btn-box'>
									<p>컴퓨터 완제품 구입처</p>
									<div>
										<a href='https://foryoucom.co.kr/' target='_blank'><LinkIcon className='link-icon' /><p>포유컴 가기</p></a>
										<a href='https://www.compuzone.co.kr/' target='_blank'><LinkIcon className='link-icon' /><p>컴퓨존 가기</p></a>
										<a href='https://www.commawang.co.kr/' target='_blank'><LinkIcon className='link-icon' /><p>컴마왕 가기</p></a>
										<a href='https://danawa.com/' target='_blank'><LinkIcon className='link-icon' /><p>다나와 가기</p></a>
										<a href='https://www.assacom.com/' target='_blank'><LinkIcon className='link-icon' /><p>아싸컴 가기</p></a>
									</div>
								</div>
							</div>
						</div>
					</div>
			}
		</>
	)
}

export default Recommend