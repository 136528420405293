import React from 'react'
import useFormDataStore from 'store/formState'
import useModalStore from 'store/modalState'

const browserAList = [
	{
		text: '5개 탭',
		data: 5
	},
	{
		text: '10개 탭',
		data: 10
	},
	{
		text: '15개 탭',
		data: 15
	},
	{
		text: '20개 탭',
		data: 20
	},
	{
		text: '30개 탭 이상',
		data: 30
	},
]

const BrowserForms = ({ step, setStep }) => {
	const formResultData = useFormDataStore((state) => state.formResultData)
	const updateFormResultData = useFormDataStore((state) => state.updateFormResultData)
	const updateFormResultState = useFormDataStore((state) => state.updateFormResultState)
	const { openModal, closeModal } = useModalStore();

	const isListActive = (itemIdx) => {
		const activeList = formResultData.Q7?.aList.map((item) => { return item.num })
		return activeList?.includes(itemIdx) ? 'active' : ''
	}


	return (
		<div>
			<div className='q'>
				<span>Q7.</span>
				<h3>인터넷 하시면서 창을 몇 개 정도 띄우시나요?</h3>
			</div>
			<ul className='a'>
				{browserAList.map((item, i) => (
					<li key={`qna-list-item-${i}`} className={isListActive(i + 1)} onClick={() => {
						updateFormResultData({
							...formResultData,
							Q7: {
								q: '인터넷 하시면서 창을 몇 개 정도 띄우시나요?',
								aList: [
									{
										num: i + 1,
										text: item.text
									},
								],
								data: item.data
							},
						})
						updateFormResultState('Q7')
					}}>
						<div>
							<span>
								{i + 1}
							</span>
						</div>
						<p>
							{item.text}
						</p>
					</li>))}
			</ul>
			<div className='btn-container'>

				<button className='btn btn-prev' onClick={() => {
					setStep(step - 1)
				}}>
					뒤로
				</button>
				<button
					className='btn btn-next'
					onClick={() => {
						if (formResultData.Q7) {
							setStep(step + 1)
						} else {
							openModal({
								title: '답변 선택이 필요해요',
								message: `답변을 필수로 해주셔야 더욱 더 정확하게\n컴퓨터를 추천해드릴 수 있습니다.`,
								mainBtn: {
									text: '네 선택할게요',
									fnc: () => { closeModal() },
								}
							})
						}
					}}>다음</button>
			</div>
		</div>
	)
}

export default BrowserForms