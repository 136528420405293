export const formData = [
	{
		type: 'qna',
		q: '혹시 컴퓨터를 어떻게 사용하실 예정 이신가요?',
		qs: '(하나만 선택해 주세요)',
		a: [
			{
				text: '게임을 즐겨요',
				data: 'GAME'
			},
			{
				text: '이제 시작하는 초보 디자이너',
				data: 'BEGINNER_DESIGNER'
			},
			{
				text: '전문적으로 디자인을 해요',
				data: 'PRO_DESIGNER'
			},
			{
				text: '크리에이터 준비 중이에요',
				data: 'CREATER_IN_PREPARATION'
			},
			{
				text: '영상 또는 이미지 편집을 주로해요',
				data: 'VIDEO_IMAGE_EDITOR'
			},
			{
				text: '코드를 다루는 개발자에요',
				data: 'DEVELOPER'
			},
			{
				text: '가벼운 업무로 사용할거에요',
				data: 'OFFICE'
			},
		]
	}, {
		type: 'qna',
		q: '연령대를 부탁 드려요!',
		qs: '(하나만 선택해 주세요)',
		a: [
			{
				text: '10대',
				data: 10
			},
			{
				text: '20대',
				data: 20
			},
			{
				text: '30대',
				data: 30
			},
			{
				text: '40대',
				data: 40
			},
			{
				text: '50대',
				data: 50
			},
			{
				text: '60대 이상',
				data: 60
			},
		]
	}, {
		type: 'qna',
		q: '성별을 부탁 드려요',
		a: [
			{
				text: '여자',
				data: 'WOMEN'
			},
			{
				text: '남자',
				data: 'MAN'
			},
		]
	}, {
		type: 'qna',
		q: '컴퓨터는 사양에 따라 가격이 높아져요! 혹시 어느정도 사양을 생각 하실까요?',
		a: [
			{
				text: '아주 빠르지 않아도 돼요',
				data: 'ANY'
			},
			{
				text: '게임은 아니어도 적당하게',
				data: 'NOMAL'
			},
			{
				text: '이제 시작하는 초보 디자이너',
				data: 'MULTI'
			},
			{
				text: '어떤 작업도 가능한 슈퍼컴',
				data: 'SUPER'
			},
		]
	}
]

export const surveyList = [
	{
		label: '게임',
		id: 'GAME'
	},
	{
		label: '프로그램',
		id: 'SOFTWARE'
	},
	{
		label: '인터넷창',
		id: 'BROWSER'
	},
	{
		label: '메신저',
		id: 'SNS'
	},
	{
		label: '가격대',
		id: 'PRICE'
	},
	{
		label: '분석',
		id: 'ANALYZE'
	},
	{
		label: '추천',
		id: 'RECOMMEND'
	},
]