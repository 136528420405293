import React, { useEffect, useState } from 'react'
import { formData, surveyList } from 'utils/formData';
import FormResult from 'components/common/FormResult';
import { survey } from 'services/survey';
import DetailedForms from './DetailedForms';

const DetailedFormsLayout = () => {
	const [step, setStep] = useState(0);

	// useEffect(() => {
	// 	survey.get((res) => {
	// 		console.log(res)
	// 	})
	// }, [])

	return (
		<div id='detail-forms' className='contents'>
			<div className='form-container'>
				<div className='tooltip'>
					피씨스터가 PC를 추천하기 위해, 아래 정보를 선택해 주세요!
				</div>
				<ul className='progress-bar'>
					{surveyList.map((item, i) => (
						<li key={`survey-progress-bar-item-${i}`} className={`stepper-item ${step === i ? 'active' : ''}`}>
							<div className='step-counter'>{i + 1}</div>
							<div className='step-name'>{item.label}</div>
						</li>))}
				</ul>
				<DetailedForms step={step} setStep={setStep} />
			</div>
			<FormResult />
		</div>
	)
}

export default DetailedFormsLayout