import React from 'react'

const Copyright = () => {
	return (
		<div className='copyright'>
			copyright ⓒ all rights resvered
		</div>
	)
}

export default Copyright