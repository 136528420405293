import React from 'react'
import { useNavigate } from 'react-router-dom'
import useFormDataStore from 'store/formState'
import useModalStore from 'store/modalState'

const priceAList = [
	{
		text: '100만원 이하',
		data: 'P_100_D'
	},
	{
		text: '100만원 ~ 200만원',
		data: 'P_100_200'
	},
	{
		text: '200만원 ~ 300만원',
		data: 'P_200_300'
	},
	{
		text: '300만원 ~ 400만원',
		data: 'P_300_400'
	},
	{
		text: '400만원 이상',
		data: 'P_400_U'
	},
]

const PriceForms = ({ step, setStep }) => {
	const formResultData = useFormDataStore((state) => state.formResultData)
	const updateFormResultData = useFormDataStore((state) => state.updateFormResultData)
	const updateFormResultState = useFormDataStore((state) => state.updateFormResultState)
	const { openModal, closeModal } = useModalStore();
	const navigate = useNavigate();

	const isListActive = (itemIdx) => {
		const activeList = formResultData.Q9?.aList.map((item) => { return item.num })
		return activeList?.includes(itemIdx) ? 'active' : ''
	}


	return (
		<div>
			<div className='q'>
				<span>Q9.</span>
				<h3>희망하시는 가격대를 선택해 주세요!</h3>
			</div>
			<ul className='a'>
				{priceAList.map((item, i) => (
					<li key={`qna-list-item-${i}`} className={isListActive(i + 1)} onClick={() => {
						updateFormResultData({
							...formResultData,
							Q9: {
								q: '희망하시는 가격대를 선택해 주세요!',
								aList: [
									{
										num: i + 1,
										text: item.text
									},
								],
								data: item.data
							}
						})
						updateFormResultState('Q9')
					}}>
						<div>
							<span>
								{i + 1}
							</span>
						</div>
						<p>
							{item.text}
						</p>
					</li>))}
			</ul>
			<div className='btn-container'>

				<button className='btn btn-prev' onClick={() => {
					setStep(step - 1)
				}}>
					뒤로
				</button>
				<button
					className='btn btn-next'
					onClick={() => {
						if (formResultData.Q9) {
							navigate('/recommend')
						} else {
							openModal({
								title: '답변 선택이 필요해요',
								message: `답변을 필수로 해주셔야 더욱 더 정확하게\n컴퓨터를 추천해드릴 수 있습니다.`,
								mainBtn: {
									text: '네 선택할게요',
									fnc: () => { closeModal() },
								}
							})
						}
					}}>다음</button>
			</div>
		</div>
	)
}

export default PriceForms