import React from 'react'
import banner_temp1 from 'assets/images/banner_temp1.png'

const TopBanner = ({ banner }) => {
	return (
		<div className='banner banner-top'>
			<a href={banner?.url} target="_blank">
				<img src={banner?.imageUrl} alt="" />
			</a>
		</div>
	)
}

export default TopBanner