import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { software } from 'services/software'
import GameForms from './GameForms'
import { surveyList } from 'utils/formData'
import SoftwareForms from './SoftwareForms'
import BrowserForms from './BrowserForms'
import SnsForms from './SNSForms'
import PriceForms from './PriceForms'

const DetailedForms = ({ step, setStep }) => {
	const navigate = useNavigate();
	const [softwareData, setSoftwareData] = useState([]);
	const [gameData, setGameData] = useState([]);

	useEffect(() => {
		software.get({ type: 'GAME', use: true }, (res) => {
			setGameData(res.content)
		})
		software.get({ type: 'SOFTWARE', use: true }, (res) => {
			setSoftwareData(res.content)
		})
	}, [])
	switch (surveyList[step]?.id) {
		case 'GAME':
			return (<GameForms step={step} setStep={setStep} data={gameData} />)
		case 'SOFTWARE':
			return (<SoftwareForms step={step} setStep={setStep} data={softwareData} />)
		case 'BROWSER':
			return (<BrowserForms step={step} setStep={setStep} />)
		case 'SNS':
			return (<SnsForms step={step} setStep={setStep} />)
		case 'PRICE':
			return (<PriceForms step={step} setStep={setStep} />)
		default:
			return;
	}
}

export default DetailedForms