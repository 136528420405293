import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { formData } from 'utils/formData';
import QnaForms from './QnaForms';
import FormResult from 'components/common/FormResult';

const Forms = () => {
	const [step, setStep] = useState(0);

	const location = useLocation();
	useEffect(() => {
		if (location.state?.step) {
			setStep(location.state.step);
		}
	}, [location])

	return (
		<div id='forms' className='contents'>
			<div>
				<div className='tooltip'>
					피씨스터가 PC를 추천하기 위해, 아래 정보를 선택해 주세요!
				</div>
				<QnaForms formData={formData} step={step} setStep={setStep} />
			</div>
			<FormResult />
		</div>
	)
}

export default Forms