import { create } from 'zustand';


const useModalStore = create((set) => ({
	state: false,
	value: {
		title: '',
		message: '',
		subBtn: {
			text: '',
			fnc: () => { },
		},
		mainBtn: {
			text: '',
			fnc: () => { },
		}
	},
	openModal: (value) => set(() => ({
		state: true,
		value,
	})),
	closeModal: () => set(() => ({
		state: false,
		value: {
			title: '',
			message: '',
			subBtn: {
				text: '',
				fnc: () => { },
			},
			mainBtn: {
				text: '',
				fnc: () => { },
			}
		},
	}))
}));

export default useModalStore;