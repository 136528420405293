import React from 'react'
import useFormDataStore from 'store/formState'
import sns_kakaotalk from 'assets/images/sns_kakaotalk.png';
import sns_line from 'assets/images/sns_line.png';
import sns_telegram from 'assets/images/sns_telegram.png';
import sns_facebook from 'assets/images/sns_facebook.png';
import sns_instagram from 'assets/images/sns_instagram.png';
import useModalStore from 'store/modalState';

const snsAImgList = [
	{
		id: 'KAKAOTALK',
		img: sns_kakaotalk
	},
	{
		id: 'LINE',
		img: sns_line
	},
	{
		id: 'TELEGRAM',
		img: sns_telegram
	},
	{
		id: 'FACEBOOK',
		img: sns_facebook
	},
	{
		id: 'INSTARGRAM',
		img: sns_instagram
	},
]
const snsATextList = [
	{
		text: '1개',
		data: 1
	},
	{
		text: '10개',
		data: 10
	},
	{
		text: '15개',
		data: 15
	},
	{
		text: '20개',
		data: 20
	},
	{
		text: '30개',
		data: 30
	},
]

const SnsForms = ({ step, setStep }) => {
	const formResultData = useFormDataStore((state) => state.formResultData)
	const updateFormResultData = useFormDataStore((state) => state.updateFormResultData)
	const updateFormResultState = useFormDataStore((state) => state.updateFormResultState)
	const { openModal, closeModal } = useModalStore();

	const isListActive = (itemIdx) => {
		const activeList = formResultData.Q8?.aList.map((item) => { return item.num })
		return activeList?.includes(itemIdx) && activeList?.indexOf(itemIdx) + 1
	}

	return (
		<div>
			<div className='q'>
				<span>Q8.</span>
				<h3>사용하시는 메신저와, 한번에 몇 개의 대화창을 띄우시나요? (복수 선택 가능)</h3>
			</div>
			<ul className='a-img'>
				{snsAImgList.map((item, i) => (
					<li key={`game-list-item-${i}`} className={isListActive(item.id) ? 'active' : ''} onClick={() => {
						const isActiveIdx = isListActive(item.id);
						if (isActiveIdx) {
							let aListCopy = [...formResultData.Q8?.aList];
							let dataCopy = [...formResultData.Q8?.data];
							aListCopy.splice(isActiveIdx - 1, 1)
							dataCopy.splice(isActiveIdx - 1, 1)
							updateFormResultData({
								...formResultData,
								Q8: {
									q: '사용하시는 메신저와, 한번에 몇 개의 대화창을 띄우시나요?',
									aList: [
										...aListCopy
									],
									a2: formResultData.Q8?.a2,
									data: [...dataCopy]
								}
							})
						} else {
							updateFormResultData({
								...formResultData,
								Q8: {
									q: '사용하시는 메신저와, 한번에 몇 개의 대화창을 띄우시나요?',
									aList: [
										...formResultData.Q8?.aList || [],
										{
											num: item.id,
											img: item.img
										},
									],
									a2: formResultData.Q8?.a2,
									data: [
										...formResultData.Q8?.data || [],
										item.id
									]
								}
							})
						}
						updateFormResultState('Q8')
					}}>
						<div className="img-box">
							<img src={item.img} alt="" />
							<div className="overlay"></div>
						</div>
					</li>
				))}
			</ul>
			<ul className='a'>
				{snsATextList.map((item, i) => (
					<li key={`qna-list-item-${i}`} className={formResultData.Q8?.a2?.text === item.text ? 'active' : ''} onClick={() => {
						updateFormResultData({
							...formResultData,
							Q8: {
								q: '사용하시는 메신저와, 한번에 몇 개의 대화창을 띄우시나요?',
								aList: [...formResultData.Q8?.aList || []],
								a2: {
									...item,
									num: i + 1
								},
								data: [
									...formResultData.Q8?.data || []
								]
							}
						})
						updateFormResultState('Q8')
					}}>
						<div>
							<span>
								{i + 1}
							</span>
						</div>
						<p>
							{item.text}
						</p>
					</li>))}
			</ul>
			<div className='btn-container'>

				<button className='btn btn-prev' onClick={() => {
					setStep(step - 1)
				}}>
					뒤로
				</button>
				<button
					className='btn btn-next'
					onClick={() => {
						if (formResultData.Q8?.aList.length && formResultData.Q8?.a2?.num) {
							setStep(step + 1)
						} else {
							openModal({
								title: '답변 선택이 필요해요',
								message: `답변을 필수로 해주셔야 더욱 더 정확하게\n컴퓨터를 추천해드릴 수 있습니다.`,
								mainBtn: {
									text: '네 선택할게요',
									fnc: () => { closeModal() },
								}
							})
						}
					}}>다음</button>
			</div>
		</div>
	)
}

export default SnsForms