import React, { useEffect } from 'react'
import useModalStore from 'store/modalState'

const MessageModal = () => {
	const { value } = useModalStore();

	return (
		<div className='modal'>
			<div className="modal-box">
				<h3 className='modal-title'>
					{value.title}
				</h3>
				<div className='modal-message'>
					{value.message}
				</div>
				<div className='modal-btn-container'>
					{value.subBtn && <button className='btn btn-sub' onClick={() => { value.subBtn.fnc() }}>{value.subBtn.text}</button>}
					<button className='btn btn-primary' onClick={() => { value.mainBtn.fnc() }}>{value.mainBtn.text}</button>
				</div>
			</div>
			<div className="modal-continer"></div>
		</div>
	)
}

export default MessageModal