import React from 'react'

const BottomBanner = ({ bannerL, bannerC, bannerR }) => {
	return (
		<div className='banner-bottom-container'>
			<div className='banner banner-bottom'>
				<a href={bannerL?.url} target="_blank">
					<img src={bannerL?.imageUrl} alt="" />
				</a>
			</div>
			<div className='banner banner-bottom'>
				<a href={bannerC?.url} target="_blank">
					<img src={bannerC?.imageUrl} alt="" />
				</a>
			</div>
			<div className='banner banner-bottom'>
				<a href={bannerR?.url} target="_blank">
					<img src={bannerR?.imageUrl} alt="" />
				</a>
			</div>
		</div>
	)
}

export default BottomBanner