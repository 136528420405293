import api from "../api";


export const software = {
	get(params, callback) {
		api.get('/software',
			{
				params
			}
		).then((res) => {
			if (res.code !== 10000) return;
			callback(res.data);
		})
	}
};