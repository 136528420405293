import axios from 'axios';


const api = axios.create({
	baseURL: process.env.REACT_APP_API_HOST + '/api',
	headers: {
		'Content-Type': 'application/json',
	}
})

api.interceptors.response.use(
	response => {
		const res = response.data
		return res
	},

	error => {
		// console.log('응답 에러:', error.response);
		if (error.response && error.response.status === 401) {
			// localStorage.clear()
			// window.dispatchEvent(new Event('storage'))
		}
		if (error.response && error.response.status === 403) {
			// localStorage.clear()
			// window.dispatchEvent(new Event('storage'))
		}
		return Promise.reject(error);
	}
);


export default api;