import React, { useEffect, useState } from 'react'
import { Outlet } from "react-router-dom";
import Header from './Header';
import TopBanner from 'components/common/banner/TopBanner';
import FloatBanner from 'components/common/banner/FloatBanner';
import Copyright from './Copyright';
import BottomBanner from 'components/common/banner/BottomBanner';
import MessageModal from 'components/common/modal/MessageModal';
import useModalStore from 'store/modalState';
import { banner } from 'services/banner';

const Layout = () => {
	const { state: modalState } = useModalStore();
	const [bannerList, setBannerList] = useState([])

	useEffect(() => {
		banner.get({}, (res) => {
			setBannerList(res)
		})
	}, [])

	const bannerFind = (type) => {
		if (!bannerList.length) return;
		return bannerList.find((item) => {
			return item.bannerType === type
		})
	}

	return (
		<div className='container'>
			<Header />
			<div className='layout'>
				<div className='blank'></div>
				<TopBanner banner={bannerFind('MAIN')} />
				<FloatBanner bannerTop={bannerFind('RIGHT_UP')} bannerBottom={bannerFind('RIGHT_DOWN')} />
				<Outlet />
				<BottomBanner bannerL={bannerFind('BUTTOM_LEFT')} bannerC={bannerFind('BUTTOM_CENTER')} bannerR={bannerFind('BUTTOM_RIGHT')} />
				<Copyright />
				{modalState && <MessageModal />}
			</div>
		</div>
	)
}

export default Layout