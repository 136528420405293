import React from 'react'

const FloatBanner = ({ bannerTop, bannerBottom }) => {
	return (
		<div className='banner-float-container'>
			<div className='sticky'>
				<div className='banner banner-float'>
					<a href={bannerTop?.url} target="_blank">
						<img src={bannerTop?.imageUrl} alt="" />
					</a>
				</div>
				<div className='banner banner-float'>
					<a href={bannerBottom?.url} target="_blank">
						<img src={bannerBottom?.imageUrl} alt="" />
					</a>
				</div>
			</div>
		</div>
	)
}

export default FloatBanner