import React from 'react'
import { useNavigate } from "react-router-dom";
import main_logo from 'assets/logo/main_logo.png'
import main_back1 from 'assets/images/main_back1.png';
import main_back2 from 'assets/images/main_back2.png';
const Main = () => {
	const navigate = useNavigate();

	return (
		<div id='main'>
			<div className='logo'>
				<img src={main_logo} alt="" />
			</div>
			<div>
				<h1>
					피씨스터 메인 문구가 들어갑니다<br />
					피씨스터 메인 문구가 들어갑니다 피씨스터 메인 문구
				</h1>
				<h3>피씨스터 메인 서브 설명 문구가 들어갑니다.</h3>
			</div>
			<button className='btn' onClick={() => { navigate('/forms') }}>VIEW MORE</button>
			<div className='background'>
				<img className='back_img back_img1' src={main_back1} alt="" />
				<img className='back_img back_img2' src={main_back2} alt="" />
			</div>
		</div>
	)
}

export default Main